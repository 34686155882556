/* style.css*/
@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale(.3);
    }

    50% {
        opacity: 1;
    }
}

.zoomIn {
    animation-name: zoomIn;
}

@keyframes zoomOut {
    from {
        opacity: 1;
    }

    50% {
        opacity: 0;
        transform: scale(.3);
    }

    to {
        opacity: 0;
    }
}
.zoomOut {
    animation-name: zoomOut;
}
