@import "/src/assets/style/theme.scss";

.root {
  flex: 1;
  display: flex;
  background: transparent;
  box-sizing: border-box;
  flex-basis: auto;
  border-radius: 2px;
  color: $--color-text-primary;
  cursor: text;
  position: relative;
  font-size: $--font-size-large;
  align-items: center;
  font-family: 'Microsoft Yahei', 'PingFangSC', Arial, sans-serif;
  font-weight: 400;
  line-height: 1.1876em;

  &:global.error {
    border-color: $--color-danger;
  }

  &:global.disabled {
    background-color: $--background-color-contrast-text;
    color: $--color-text-hint;
    input {
      background-color: $--background-color-contrast-text;
    }
  }

  &:global.multiline {
    padding: 6px 0 7px;
  }


}


.sht-input-affix-wrapper {
  padding: 2px 8px;
  overflow: hidden;
  border: 1px solid lightgray;
  border-radius: 2px;
}

.sht-input-affix-wrapper:hover,
.sht-input-affix-wrapper:focus-within {
  border-color: #000;
}

.sht-input-affix-wrapper input {
  padding: 0;
  border: none;
  outline: none;
}

.sht-input-clear-icon {
  font-size: 12px;
  cursor: pointer;
}

.sht-input-clear-icon-hidden {
  display: none;
}
