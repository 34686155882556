@import "/src/assets/style/theme.scss";

.root {
    width: 100%;
    min-height: 100vh;
    background-color: #F75735;
    padding-bottom: 10px;
}

.imgSpan {
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    width: initial;
    height: initial;
    background: none;
    opacity: 1;
    border: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.image {
    width: 100%;
    position: relative;
    min-height: 381px;
    height: 381px;
}

.background {
    position: absolute;
    inset: 0;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center center;
}

.logo{
    top: 10px;
    left: 14px;
    position: absolute;
    width: 117px;
    height: 43px;
}

.explain {
    position: absolute;
    bottom: 20px;
    color: #FEFEFE;
    font-size: 12px;
    text-decoration-line: none;
}


.formBlock {
    width: 100%;
    padding: 0 18px;
}

.outer {
    padding: 4px;
    background: #FDC886;
    border-radius: 12px;
    box-shadow: 0 0 10px 1px rgba(#CE2828,0.5);
}

.inner {
    width: 100%;
    height: 100%;
    background: $--color-white;
    border-radius: 12px;
    padding: 20px 14px;
}

.btnOuter{
    width: 100%;
    background: #EC3636;
    border-radius: 24px;
    padding-bottom: 2px;
}

.btn{
    &:global.sht-btn-contained {
        border-radius: 24px;
        background: linear-gradient(180deg, #FFF0A7, #FFC151);
        border: 0;
        font-weight: bold;
        color: #EF110C;
        span{
            font-size: $--font-size-large;
        }
    }
}

.rules{
    margin: 10px 0;
    width: 100%;
    height: 271px;
    background: rgba(#B71515,0.3);
    box-shadow: 0 0 10px 1px rgba(206,40,40,0.5);
    border-radius: 12px 12px 12px 12px;
    padding: 20px 18px;
    color: $--color-white;
}

.rulesTitle{
    line-height: 22px;
    text-align: center;
    font-size: $--font-size-medium;
    margin-bottom: 14px;
}

.ul{
    line-height: 24px;
    display: flex;
    flex-direction: column;
}

.li{
    font-size: $--font-size-base;
}