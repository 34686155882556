/* Transition
-------------------------- */
$--all-transition: all .3s cubic-bezier(.645, .045, .355, 1);
$--fade-transition: opacity 300ms cubic-bezier(0.23, 1, 0.32, 1);
$--fade-linear-transition: opacity 200ms linear;
$--md-fade-transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 300ms cubic-bezier(0.23, 1, 0.32, 1);
$--border-transition-base: border-color .2s cubic-bezier(.645, .045, .355, 1);
$--color-transition-base: color .2s cubic-bezier(.645, .045, .355, 1);

/* Color
-------------------------- */
$--color-primary: #BF2722;
$--color-white: #FFFFFF;
$--color-black: #000000;
$--color-success: #52C41A;
$--color-warning: #FF7800;
$--color-danger: #F5222D;
$--color-info: #1B7CD7;


/*---------------- ------- 颜色 ------- ----------------- */
/*------ 背景颜色 ------- */
$--background-color-main: #CCCCCC;
$--background-color-light: #FAFAFA;
$--background-color-dark: #D9D9D9;
$--background-color-contrast-text: #F0F0F0;
$--background-color-black: #000000;
$--background-color-white: #FFFFFF;
$--background-color-default: #F6F6F6;
/*------ 背景颜色 ------- */

/*------ 字体颜色 ------- */
$--color-text-primary: #333333;
$--color-text-secondary: #666666;
$--color-text-disabled: #999999;
$--color-text-hint: #CCCCCC;
/*------ 字体颜色 ------- */

/*------ 边框颜色 ------- */
$--border-color-main: #CCCCCC;
$--border-color-light: #FAFAFA;
$--border-color-dark: #D9D9D9;
$--border-color-default: #F6F6F6;
$--border-color-contrast-text: #F0F0F0;
/*------ 边框颜色 ------- */

/*------ 激活颜色 ------- */
$--action-active: rgba(0, 0, 0, 0.54);
$--action-hover: rgba(0, 0, 0, 0.04);
$--action-selected: rgba(0, 0, 0, 0.08);
$--action-disabled: rgba(0, 0, 0, 0.26);
$--action-focus: rgba(0, 0, 0, 0.12);
$--action-disabled-background: rgba(0, 0, 0, 0.12);

/*------ 激活颜色 ------- */
/*---------------- ------- 颜色 ------- ----------------- */

// 透明度
$--action-focus-opacity: 0.12;
$--action-disabled-opacity: 0.38;
$--action-hover-opacity: 0.06;

// Box-shadow
$--box-shadow-0: none;
$--box-shadow-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
$--box-shadow-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
$--box-shadow-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
$--box-shadow-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
$--box-shadow-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);


/*----------- 字体大小 ------------ */
$--font-size-h1: 96px;
$--font-size-h2: 60px;
$--font-size-h3: 48px;
$--font-size-h4: 34px;
$--font-size-h5: 24px;
$--font-size-h6: 20px;
$--font-size-large: 18px;
$--font-size-medium: 16px;
$--font-size-base: 14px;
$--font-size-typography: 13px;
$--font-size-small: 12px;
/*----------- 字体大小 ------------ */

/* ---------- z-exhibition ---------- */
$--index-normal: 1;
$--index-top: 1000;
$--index-app-bar: 1200;
$--index-nprogress: 1800;
$--index-drawer: 1300;
$--index-modal: 1500;
$--index-tooltip: 2000;
/* ---------- z-exhibition ---------- */

