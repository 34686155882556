@import "/src/assets/style/theme.scss";

.root {
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 12px;
  overflow: visible;
  font-size: $--font-size-base;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
