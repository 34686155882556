@import "/src/assets/style/theme.scss";

.root {
  display: inline-block;
  border-radius: 2px;
  font-size: $--font-size-medium;
  position: relative;

  &:global.sht-btn-fullwidth {
    width: 100%;
  }

  &:global.sht-btn-loading {
    pointer-events: none;
  }

  &:global.sht-btn-loading:not(.sht-btn-text) {
    &::before {
      content: '';
      position: absolute;
      left: -1px;
      right: -1px;
      top: -1px;
      bottom: -1px;
      border-radius: 2px;
    }
  }

  &:global.sht-btn-text {
    padding: 0 8px;
    color: $--color-primary;
    &:hover {
      color: $--color-primary;
    }
  }

  &:global.sht-btn-outlined {
    border: 1px solid $--color-primary;
    color: $--color-primary;

    &:hover {
      background-color: rgba($--color-primary, 0.06);
    }
  }

  &:global.sht-btn-contained {
    border: 1px solid $--color-primary;
    background-color: $--color-primary;
    color: $--color-white;

    &:hover {
      background-color: darken($--color-primary, 8%);
      border-color: $--color-primary;
    }
  }

  &:global.sht-btn-small:not(.sht-btn-text) {
    height: 28px;
    line-height: 28px;
    padding: 0 8px;
  }

  &:global.sht-btn-medium:not(.sht-btn-text) {
    height: 32px;
    line-height: 32px;
    padding: 0 16px;
  }

  &:global.sht-btn-large:not(.sht-btn-text) {
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    font-size: $--font-size-base;
  }

  &:global.sht-btn-error.sht-btn-text {
    color: $--color-danger;
  }

  &:global.sht-btn-default {
  }

  &:global.sht-btn-primary {
  }

  &:global.sht-btn-secondary {
  }

  &:global.sht-btn-disabled {
    pointer-events: none;
    background-color: $--background-color-contrast-text;
    color: $--color-text-hint;
    border-color: $--border-color-dark;
  }

  &:global.sht-btn-text.sht-btn-disabled {
    background-color: transparent;
  }
}

.primaryBtnRoot {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    font-size: $--font-size-medium;
    animation: loading 1s infinite linear;
    color: $--color-white;
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.textBtnRoot {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;

  & > svg {
    font-size: $--font-size-medium;
    animation: loading 1s infinite linear;
    color: $--color-text-disabled;
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
